import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom
import { getFirestore, collection, query, onSnapshot, orderBy } from "firebase/firestore";

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);

const Series = () => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // Create a reference to the series collection
    const seriesCollection = collection(db, 'series');

    // Create a query to sort series by title
    const q = query(seriesCollection, orderBy("title"));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, snapshot => {
      const seriesList = snapshot.docs.map(doc => ({
        id: doc.id, // Adding the document ID for each series
        key: doc.data().key,
        title: doc.data().title,
        posterUrl: doc.data().posterUrl
      }));

      setSeries(seriesList);
    });

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  // Function to handle button click
  const handleAddSeriesClick = () => {
    window.location.href = '/seriesdetails?mode=add';
  };

  return (
    <div className="form-container">
      <div className="breadcrumbs">
        /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
        /&nbsp; Series
      </div>
      <h1 className="form-title">Series</h1>
      <button type="submit" className="form-button" onClick={handleAddSeriesClick}>Add a Series</button>

      <br />
      <br />

      {/* Loop through the series array and create a list item and a link for each */}
      {series.map(series => (
        <li key={series.id}>
          <Link to={`/seriesdetails?seriesKey=${series.key}&seriesTitle=${series.title}`}>{series.title}</Link>
        </li>
      ))}

    </div>
  );
};

export default Series;
