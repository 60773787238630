import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, onSnapshot, orderBy } from "firebase/firestore";

import './Forms.css';

import MovieList from './MovieList';

import firebaseApp from './firebaseConfig';

const db = getFirestore(firebaseApp);

const Upcoming = () => {
  const [movies, setMovies] = useState([]);

  // Get the list of movies when the screen loads.
  useEffect(() => {
    // Create a reference to the movies collection
    const moviesCollection = collection(db, 'movies');

    // Create a query to sort movies by title
    const q = query(
      moviesCollection,
      orderBy("title")
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q,
      snapshot => {
        const movieList = snapshot.docs.map(doc => ({
          title: doc.data().title,
          key: doc.data().key,
          genres: doc.data().genres,
          lessons: doc.data().lessons,
          largeUrl: doc.data().largeUrl,
          posterUrl: doc.data().posterUrl,
          thumbnailUrl: doc.data().thumbnailUrl,
          url: doc.data().url,
        }));

        setMovies(movieList);
      },
      error => {
        console.error("Error fetching movies:", error);
      }
    );

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div className="movie-list-container">

      {/* BREADCRUMBS */}
      <div className="breadcrumbs">
        /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
        /&nbsp; Movies
      </div>

      <br />
      <br />

      {/* MOVIE LIST */}
      {movies &&
        <MovieList movies={movies} />
      }

    </div>
  );
};

export default Upcoming;
