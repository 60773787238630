import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';
import { addMonths, parseISO, compareDesc } from 'date-fns';
import _ from 'lodash'; // Import Lodash

// Firebase
import { getFirestore, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import firebaseApp from './firebaseConfig';

// Styles
import './GenreList.css';

function GenreList({ movies }) {
  // State Variables
  const [genres, setGenres] = useState([]);
  const [history, setHistory] = useState([]);
  const [recent, setRecent] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const stableMovies = useDeepCompare(movies);  // Use custom hook

  const user = useUser();

  useEffect(() => {
    // Your effect code
    if (stableMovies) {
      const recentMovies = getRecentMovies(stableMovies);
      setRecent(recentMovies);
    }
  }, [stableMovies]);


  // Initialize the Firestore instance with the initialized Firebase app
  const db = getFirestore(firebaseApp);

  // Get the list of genres and history when the screen loads.
  useEffect(() => {
    // Subscribe to real-time updates for genres
    const genresUnsubscribe = subscribeToGenres();

    // Subscribe to real-time updates for history
    const historyUnsubscribe = subscribeToHistory();

    // Unsubscribe when the component unmounts
    return () => {
      genresUnsubscribe();
      historyUnsubscribe();
    };
  }, []);

  // Custom hook to perform deep comparison
  function useDeepCompare(value) {
    const ref = useRef();  // Hold a ref to the previous value
    if (!_.isEqual(ref.current, value)) {  // Deep equality check
      ref.current = value;  // Update ref to hold the new value
    }

    return ref.current;
  }

  // Function to subscribe to real-time updates for genres
  const subscribeToGenres = () => {
    // Create a reference to the genres collection
    const genresCollection = collection(db, 'genres');

    // Create a query to sort genres by name
    const q = query(
      genresCollection,
      orderBy("name")
    );

    // Subscribe to real-time updates
    return onSnapshot(q,
      snapshot => {
        const genreList = snapshot.docs.map(doc => ({
          key: doc.data().key,
          name: doc.data().name
        }));
        setGenres(genreList);
        setLoaded(true);
      },
      error => {
        console.error("Error fetching genres:", error);
      }
    );
  }

  const subscribeToHistory = () => {
    // Create a reference to the history collection
    const historyCollection = collection(db, 'history');

    // Create a query to sort history by viewDate in descending order (most recent first)
    // And filter by the current user's key
    const q = query(
      historyCollection,
      where('userKey', '==', user.key),
      orderBy("viewDate", "desc")
    );

    // Subscribe to real-time updates
    return onSnapshot(q,
      snapshot => {
        const historyList = snapshot.docs.map(doc => ({
          id: doc.id, // include the document ID
          ...doc.data() // spread the document data
        }));
        setHistory(historyList);
      },
      error => {
        console.error("Error fetching history:", error);
      }
    );
  }

  const getRecentMovies = (movies) => {
    // Get the timestamp for two months ago
    const twoMonthsAgoTimestamp = Date.now() - (1 * 30 * 24 * 60 * 60 * 1000); // 2 months in milliseconds

    //console.log("twoMonthsAgoTimestamp: " + twoMonthsAgoTimestamp);

    // Filter movies that were added in the last two months and have a defined dateAdded
    const recentMovies = movies.filter(movie => {
      if (typeof movie.dateAdded === 'undefined' || movie.dateAdded === null) {
        // Log and skip movies with undefined or null dateAdded
        //console.log("movie.dateAdded is undefined or null. Skipping movie:", movie);
        return false;
      }

      // Convert movie.dateAdded to a number for comparison
      const movieDateAddedTimestamp = movie.dateAdded.toMillis();
      //const movieDateAddedTimestamp = Number(movie.dateAdded) * 1000;

      //console.log("movie.dateAdded: " + movie.dateAdded);
      if (movie.title === "Arrival") {
        console.log("comparing " + movieDateAddedTimestamp + " to " + twoMonthsAgoTimestamp);
      }
      // Check if movieDateAddedTimestamp is after twoMonthsAgoTimestamp
      return movieDateAddedTimestamp >= twoMonthsAgoTimestamp;
    });

    // Sort by dateAdded in descending order (most recent first)
    recentMovies.sort((a, b) => {
      return Number(b.dateAdded) - Number(a.dateAdded); // This will sort in descending order
    });

    return recentMovies;
  };

  function groupMoviesByGenre(movies) {
    const groupedMovies = {};

    movies.forEach(movie => {
      if (movie.genres && typeof movie.genres === 'string') {
        movie.genres.split(',').forEach(genre => {
          const trimmedGenre = genre.trim();  // Trim the genre
          if (!groupedMovies[trimmedGenre]) {
            groupedMovies[trimmedGenre] = [];
          }
          groupedMovies[trimmedGenre].push(movie);
        });
      }
    });

    return groupedMovies;
  }

  const groupedMoviesByGenre = groupMoviesByGenre(movies);

  return (
    <div className="genre-list">
    {/* 
      <div className="genre-wrapper">
        {loaded &&
          <div className="genre-name">Continue Watching</div>
        }
        <div className="genre-movies-wrapper">
          {Array.from(new Set(history.map(h => h.movieKey))).map(uniqueMovieKey => {
            // Find the corresponding movie object based on the unique movie key
            const movie = movies.find(m => m.key === uniqueMovieKey);
            return movie ? (
              <div key={uniqueMovieKey} className="browse-movie-item">
                <Link to={`/intro?movieKey=${movie.key}`}>
                  <img
                    src={movie.thumbnailUrl || movie.posterUrl}
                    alt={`Poster for ${movie.title}`}
                    className="movie-image"
                  />
                </Link>
              </div>
            ) : null;
          })}
        </div>
      </div>
      <div className="genre-wrapper">
        {loaded &&
          <div className="genre-name">Recently Added</div>
        }
        <div className="genre-movies-wrapper">
          {recent.map(movie => {
            return (
              <div key={movie.dateAdded} className="browse-movie-item">
                <Link to={`/intro?movieKey=${movie.key}`}>
                  <img
                    src={movie.thumbnailUrl || movie.posterUrl}
                    alt={`Poster for ${movie.title}`}
                    className="movie-image"
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
        */}


      {genres.map(genre => {
        // Check if there are movies in the current genre
        const hasMovies = groupedMoviesByGenre[genre.name] && groupedMoviesByGenre[genre.name].length > 0;

        // Only render the genre wrapper if there are movies in the genre
        if (!hasMovies) {
          return null;
        }

        return (
          <div key={genre.key} className="genre-wrapper">
            <div className="genre-name">{genre.name}</div>
            <div className="genre-movies-wrapper">
              {groupedMoviesByGenre[genre.name].map(movie => (
                <div key={movie.key} className="browse-movie-item">
                  <Link to={`/intro?movieKey=${movie.key}`}>
                    <img
                      src={movie.thumbnailUrl || movie.posterUrl}
                      alt={`Poster for ${movie.title}`}
                      className="movie-image"
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      })}

    </div>
  );
}

export default GenreList;
