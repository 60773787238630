import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'; // Import the Link component from react-router-dom
import { getFirestore, collection, getDoc, setDoc, doc, query, onSnapshot, orderBy } from "firebase/firestore";

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);

const Seasons = () => {
  const [series, setSeries] = useState(null);
  const [seasons, setSeasons] = useState([]);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const seriesKey = params.get('seriesKey');
  const seriesTitle = params.get('seriesTitle');

  useEffect(() => {
    fetchSeriesDetails();
  }, [seriesKey]);

  useEffect(() => {
    // Create a reference to the seasons collection
    const seasonsCollection = collection(db, 'seasons');

    // Create a query to sort seasons by title
    const q = query(seasonsCollection, orderBy("title"));

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q, snapshot => {
      const seasonList = snapshot.docs.map(doc => ({
        id: doc.id, // Adding the document ID for each season
        key: doc.data().key,
        title: doc.data().title,
        posterUrl: doc.data().posterUrl
      }));

      setSeasons(seasonList);
    });

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  const fetchSeriesDetails = async () => {
    if (!seriesKey) return;

    const seriesRef = doc(db, 'series', seriesKey);
    const seriesSnap = await getDoc(seriesRef);
    if (seriesSnap.exists) {
      setSeries(seriesSnap.data());
    }
  };

  // Function to handle button click
  const handleAddSeasonClick = () => {
    window.location.href = '/seasondetails?mode=add&seriesKey=' + seriesKey + '&seriesTitle=' + seriesTitle;
  };

  return (
    <div className="form-container">
      <div className="breadcrumbs">
        /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
        /&nbsp; <Link to={`/series`}>Series</Link>&nbsp;
        /&nbsp; <Link to={`/seriesdetails?mode=edit&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>{series && series.title}</Link>&nbsp;
        / Seasons
      </div>
      <h1 className="form-title">
        {series &&
          series.title
        }&nbsp;- Seasons
      </h1>
      <button type="submit" className="form-button" onClick={handleAddSeasonClick}>Add a Season</button>

      <br />
      <br />
      
      {/* Loop through the seasons array and create a list item and a link for each */}
      {seasons.map(season => (
        <li key={season.id}>
          {season.posterUrl && season.posterUrl !== '' ? '✓ ' : null}
          <Link to={`/seasondetails?seriesKey=${seriesKey}&seriesTitle=${seriesTitle}&seasonKey=${season.key}`}>{season.title}</Link>
        </li>
      ))}

    </div>
  );
};

export default Seasons;
