import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Play.css';

import VideoPlayer from './VideoPlayer';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

function Play() {
  const [movie, setMovie] = useState(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const movieKey = params.get('movieKey');

  useEffect(() => {
      fetchMovieDetails();
  }, [movieKey]);

  const fetchMovieDetails = async () => {
    if (!movieKey) return;

    const movieRef = doc(db, 'movies', movieKey);
    const movieSnap = await getDoc(movieRef);
    if (movieSnap.exists) {
      setMovie(movieSnap.data());
    }
  };
  
  return (

    <div className="viewer-container">
      {movie && movie.url && (
        
        <VideoPlayer movie={movie} />

      )}
    </div>
  );
}

export default Play;
