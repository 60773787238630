import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getFirestore, setDoc, doc, getDoc, updateDoc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { useNavigate } from 'react-router-dom';  // Import useHistory

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

function SeasonDetails() {
  const [series, setSeries] = useState(null);
  const [season, setSeason] = useState(null);
  const [editableSeason, setEditableSeason] = useState({});
  const [image, setImage] = useState(null);
  const [isSaved, setIsSaved] = useState(false); // New state to handle the display of the success message

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const seriesKey = params.get('seriesKey');
  const seriesTitle = params.get('seriesTitle');
  const seasonKey = params.get('seasonKey');
  const mode = params.get('mode') || 'edit'; // Edit mode by default

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    if (mode === 'add') {
      // Initialize an empty season object for Add mode
      setSeason({});
      setEditableSeason({});
    } else {
      // Fetch existing season details for Edit mode
      fetchSeasonDetails();
    }
  }, [seasonKey, mode]);

  useEffect(() => {
    fetchSeriesDetails();
  }, [seriesKey]);

  useEffect(() => {
    fetchSeasonDetails();
  }, [seasonKey]);

  useEffect(() => {
    setEditableSeason(season);
  }, [season]);

  const fetchSeriesDetails = async () => {
    if (!seriesKey) return;

    const seriesRef = doc(db, 'series', seriesKey);
    const seriesSnap = await getDoc(seriesRef);
    if (seriesSnap.exists) {
      setSeries(seriesSnap.data());
    }
  };

  const fetchSeasonDetails = async () => {
    if (!seasonKey) return;

    const seasonRef = doc(db, 'seasons', seasonKey);
    const seasonSnap = await getDoc(seasonRef);
    if (seasonSnap.exists) {
      setSeason(seasonSnap.data());
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being changed is "duration" or "year", parse it as an integer
    const parsedValue = (name === 'duration' || name === 'year') ? parseInt(value, 10) : value;

    // Check if parsedValue is NaN (not a number). If it is, revert it back to the original value.
    setEditableSeason(prevState => ({ ...prevState, [name]: isNaN(parsedValue) ? value : parsedValue }));
  };

  // Modified function to handle season details update
  const updateSeasonDetails = async (e) => {
    e.preventDefault();

    setIsSaved(false); // Reset the saved flag before starting the update

    // Upload image to Firebase Storage if selected
    if (image) {
      const storageRef = ref(storage, `posters/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      // Wait for upload to complete and then get URL
      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed', () => { }, reject, async () => {
          const downloadURL = await getDownloadURL(storageRef);
          editableSeason.posterUrl = downloadURL;
          resolve();
        });
      });
    }

    if (mode === 'add') {
      // Generate a 40-character random key for the new season
      const newSeasonKey = generateRandomKey();

      // Add new season to Firestore with the generated key as the document name
      const seasonRef = doc(db, 'seasons', newSeasonKey);
      await setDoc(seasonRef, { ...editableSeason, key: newSeasonKey });

      // Redirect to the edit page for the newly created season
      navigate(`/seasondetails?key=${newSeasonKey}`);
    } else {
      // Update existing season
      const seasonRef = doc(db, 'seasons', seasonKey);
      await updateDoc(seasonRef, editableSeason);
    }

    // Refresh the data
    fetchSeasonDetails();

    setIsSaved(true); // Set the saved flag after successfully updating the Firestore data
  };

  // Helper function to generate a 40-character random key
  const generateRandomKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 40; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Function to handle button click
  const handleBackClick = () => {
    window.location.href = '/seasons';
  };

  // Function to handle delete click
  const handleDeleteClick = async () => {
    // Show a confirmation dialog to the user
    if (window.confirm('Are you sure you want to delete this season?')) {
      // Delete the season from Firestore
      const seasonRef = doc(db, 'seasons', seasonKey);
      await deleteDoc(seasonRef);

      // Redirect to the list page after deletion
      navigate('/list');
    }
  };

  // Function to handle button click
  const handleEpisodesClick = () => {
    window.location.href = '/episodes?seriesKey=' + seriesKey + '&seriesTitle=' + seriesTitle + '&seasonKey=' + seasonKey + '&seasonTitle=' + season.title + '&seriesKey=' + seriesKey + '&seriesTitle=' + seriesTitle;
  };

  return (

    <div className="form-container">
      {editableSeason ? (
        <form onSubmit={updateSeasonDetails}>
          <div className="breadcrumbs">
            /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
            /&nbsp; <Link to={`/series`}>Series</Link>&nbsp;
            /&nbsp; <Link to={`/seriesdetails?mode=edit&seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>{series && series.title}</Link>&nbsp;
            /&nbsp; <Link to={`/seasons?seriesKey=${seriesKey}&seriesTitle=${seriesTitle}`}>Seasons</Link>&nbsp;
            /&nbsp; {season && season.title}
          </div>
          <h1 className="form-title">{
            seriesTitle
          }&nbsp;- {mode === 'add' ? 'Add Season' : 'Edit Season Details'}</h1>

          <button type="button" className="form-button" onClick={handleDeleteClick}>Delete Season</button>
          &nbsp;&nbsp;
          <button type="button" className="form-button" onClick={handleEpisodesClick}>Episodes</button>

          <br />
          <br />

          {/* Input fields */}
          <label className="form-label">
            Title:
            <input type="text" name="title" value={editableSeason.title || ''} onChange={handleChange} className="form-input" />
          </label>

          {/* Update Button */}
          <div>
            <button type="submit" className="form-button">{mode === 'add' ? 'Add Season' : 'Update Details'}</button>

            {/* Display success message conditionally */}
            {isSaved && (
              <span className="success-message">Changes saved.</span>
            )}

          </div>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default SeasonDetails;
