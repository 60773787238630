import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { getFirestore, setDoc, doc, getDoc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import { useNavigate } from 'react-router-dom';  // Import useHistory

import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

import VideoPlayer, { StreamType } from './VideoPlayer';

import posterDefault from './posterdefault.jpg';
import thumbnailDefault from './thumbnaildefault.jpg';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

function Edit() {
  const [movie, setMovie] = useState(null);
  const [editableMovie, setEditableMovie] = useState({});
  const [posterImage, setPosterImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [thumbnailInfo, setThumbnailInfo] = useState(null); // State to store image information
  const [posterInfo, setPosterInfo] = useState(null); // State to store image information
  const [localPosterUrl, setLocalPosterUrl] = useState(null);
  const [localThumbnailUrl, setLocalThumbnailUrl] = useState(null);

  const [isSaved, setIsSaved] = useState(false); // New state to handle the display of the success message

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const movieKey = params.get('movieKey');
  const mode = params.get('mode') || 'edit'; // Edit mode by default

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    if (mode === 'add') {
      // Initialize an empty movie object for Add mode
      setMovie({});
      setEditableMovie({});
    } else {
      // Fetch existing movie details for Edit mode
      fetchMovieDetails();
    }
  }, [movieKey, mode]);

  useEffect(() => {
    fetchMovieDetails();
  }, [movieKey]);

  useEffect(() => {
    setEditableMovie(movie);
  }, [movie]);

  const fetchMovieDetails = async () => {
    if (!movieKey) return;

    const movieRef = doc(db, 'movies', movieKey);
    const movieSnap = await getDoc(movieRef);
    if (movieSnap.exists) {
      setMovie(movieSnap.data());
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being changed is "duration" or "year", parse it as an integer
    const parsedValue = (name === 'duration' || name === 'year') ? parseInt(value, 10) : value;

    // Check if parsedValue is NaN (not a number). If it is, revert it back to the original value.
    setEditableMovie(prevState => ({ ...prevState, [name]: isNaN(parsedValue) ? value : parsedValue }));
  };

  const handlePosterImageChange = (e) => {
    const file = e.target.files[0];
    setPosterImage(file);

    // Create a blob URL representing the file object
    const localUrl = URL.createObjectURL(file);
    setLocalPosterUrl(localUrl);
  };

  const handleThumbnailImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setThumbnailImage(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        handleThumbnailProperties(e.target.result);
      };
      reader.readAsDataURL(file);

      // Create a blob URL representing the file object
      const localUrl = URL.createObjectURL(file);
      setLocalThumbnailUrl(localUrl);
    }
  };

  // Handle the image properties detection
  const handleThumbnailProperties = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setThumbnailInfo({
        width: img.width,
        height: img.height,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight
      });
    };
  };

  // Handle the image properties detection
  const handlePosterProperties = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setPosterInfo({
        width: img.width,
        height: img.height,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight
      });
    };
  };

  // Modified function to handle movie details update
  const updateMovieDetails = async (e) => {
    e.preventDefault();

    setIsSaved(false); // Reset the saved flag before starting the update

    const updatedMovie = { ...editableMovie };

    // Get the current timestamp
    const currentTime = Timestamp.now();  // Use Firestore's Timestamp.now() method

    // Update dateModified always
    updatedMovie.dateModified = currentTime;

    // Update dateAdded if updatedMovie.url is set
    if (updatedMovie.url) {
      updatedMovie.dateAdded = currentTime;
    }

    // If in 'add' mode, ensure we have a unique key before processing images
    if (mode === 'add' && !editableMovie.key) {
      const newMovieKey = generateRandomKey();
      setEditableMovie(prevMovie => ({ ...prevMovie, key: newMovieKey }));
      updatedMovie.key = newMovieKey;  // Update the key in your local copy
    }

    if (posterImage) {
      // Generate the unique poster file name
      const posterFileName = `${updatedMovie.key}_poster.jpg`;
      const posterStorageRef = ref(storage, `posters/${posterFileName}`);
      const posterUploadTask = uploadBytesResumable(posterStorageRef, posterImage);

      await new Promise((resolve, reject) => {
        posterUploadTask.on('state_changed', () => { }, reject, async () => {
          const posterDownloadURL = await getDownloadURL(posterStorageRef);
          updatedMovie.posterUrl = posterDownloadURL;
          resolve();
        });
      });
    }

    if (thumbnailImage) {
      // Generate the unique thumbnail file name
      const thumbnailFileName = `${updatedMovie.key}_thumbnail.jpg`;
      const thumbnailStorageRef = ref(storage, `thumbnails/${thumbnailFileName}`);
      const thumbnailUploadTask = uploadBytesResumable(thumbnailStorageRef, thumbnailImage);

      await new Promise((resolve, reject) => {
        thumbnailUploadTask.on('state_changed', () => { }, reject, async () => {
          const thumbnailDownloadURL = await getDownloadURL(thumbnailStorageRef);
          updatedMovie.thumbnailUrl = thumbnailDownloadURL;
          resolve();
        });
      });
    }

    if (mode === 'add') {
      updatedMovie.dateAdded = currentTime; // Assuming you want to set this when adding a new movie

      // Now that the movie has a key and images have been processed, save it to Firestore
      const movieRef = doc(db, 'movies', updatedMovie.key);
      await setDoc(movieRef, updatedMovie);

      // Redirect to the edit page for the newly created movie
      navigate(`/edit?key=${updatedMovie.key}`);
    } else {
      // Update existing movie
      const movieRef = doc(db, 'movies', movieKey);
      await updateDoc(movieRef, updatedMovie);
    }

    // Refresh the data
    fetchMovieDetails();

    setIsSaved(true); // Set the saved flag after successfully updating the Firestore data
  };


  // Helper function to generate a 40-character random key
  const generateRandomKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 40; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Function to handle delete click
  const handleDeleteClick = async () => {
    // Show a confirmation dialog to the user
    if (window.confirm('Are you sure you want to delete this movie?')) {
      // Delete the movie from Firestore
      const movieRef = doc(db, 'movies', movieKey);
      await deleteDoc(movieRef);

      // Redirect to the list page after deletion
      navigate('/list');
    }
  };

  return (

    <div className="form-container">
      {editableMovie ? (
        <form onSubmit={updateMovieDetails}>
          <div className="breadcrumbs">
            /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
            /&nbsp; <Link to={`/movies`}>Movies</Link>&nbsp;
          </div>

          {/* File Input */}
          <div className='images-container'>
            <div className='images-poster-container'>
              <label className="form-label">
                <div className="poster-container">
                  <img
                    // Use the localPosterUrl if available, else use the saved posterUrl from Firestore
                    src={localPosterUrl || editableMovie.posterUrl || posterDefault}
                    alt="Large Image"
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      height: 'auto',
                      width: '100%',
                      transform: 'translate(-50%, -50%)',
                      minWidth: '100%'
                    }}
                    className='poster-image'
                    onLoad={() => handlePosterProperties(localPosterUrl || editableMovie.posterUrl)}
                  />
                </div>

                <input type="file" id="fileInputPoster" onChange={handlePosterImageChange} className="form-input" />
              </label>

              {/* Display image properties if available */}
              {posterInfo && (
                <div className="poster-info">
                  {posterInfo.naturalWidth}x{posterInfo.naturalHeight}
                </div>
              )}
            </div>

            <div className='images-thumbnail-container'>
              <label className="form-label">
                <div className="thumbnail-container">
                  <img
                    // Use the localThumbnailUrl if available, else use the saved posterUrl from Firestore
                    src={localThumbnailUrl || editableMovie.thumbnailUrl || thumbnailDefault}
                    alt="Large Image"
                    style={{
                      position: 'absolute',
                      left: '50%',
                      top: '50%',
                      height: 'auto',
                      width: '100%',
                      transform: 'translate(-50%, -50%)',
                      minWidth: '100%'
                    }}
                    className='thumbnail-image'
                    onLoad={() => handleThumbnailProperties(localThumbnailUrl || editableMovie.thumbnailUrl)}
                  />
                </div>

                <input type="file" id="fileInputThumbnail" onChange={handleThumbnailImageChange} className="form-input" />
              </label>

              {/* Display image properties if available */}
              {thumbnailInfo && (
                <div className="thumbnail-info">
                  {thumbnailInfo.naturalWidth}x{thumbnailInfo.naturalHeight}
                </div>
              )}
            </div>
          </div>

          <br />
          <br />
          <br />

          {/* Input fields */}
          <table width="100%">
            <tr>
              <td width="50%">
                <label className="form-label">
                  Title:
                  <input type="text" name="title" value={editableMovie.title || ''} onChange={handleChange} className="form-input" />
                </label>
              </td>
              <td>
                <label className="form-label">
                  File Name:
                  <input
                    type="text"
                    name="localFileName"
                    value={editableMovie.localFileName || ''}
                    onChange={handleChange}  // Assuming you have an onChange handler named handleChange
                    className="form-input"
                  />
                </label>
              </td>
            </tr>
          </table>


          <label className="form-label">
            SD URL:
            <input type="text" name="url" value={editableMovie.url || ''} onChange={handleChange} className="form-input" />
          </label>

          {editableMovie.url &&
            <VideoPlayer movie={editableMovie} streamType={StreamType.SD} />
          }

          <label className="form-label">
            HD URL:
            <input type="text" name="hdurl" value={editableMovie.hdurl || ''} onChange={handleChange} className="form-input" />
          </label>

          {editableMovie.url &&
            <VideoPlayer movie={editableMovie} streamType={StreamType.HD} />
          }

          <table width="100%">
            <tr>
              <td width="33%">
                <label className="form-label">
                  Year:
                  <input type="text" name="year" value={editableMovie.year || ''} onChange={handleChange} className="form-input" />
                </label>
              </td>
              <td width="33%">
                <label className="form-label">
                  Rating:
                  <input type="text" name="rating" value={editableMovie.rating || ''} onChange={handleChange} className="form-input" />
                </label>
              </td>
              <td>
                <label className="form-label">
                  Duration (Minutes):
                  <input type="text" name="duration" value={editableMovie.duration || ''} onChange={handleChange} className="form-input" />
                </label>
              </td>
            </tr>
          </table>

          <label className="form-label">
            Genres:
            <input type="text" name="genres" value={editableMovie.genres || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Lessons:
            <textarea name="lessons" value={editableMovie.lessons || ''} onChange={handleChange} className="form-textarea" />
          </label>

          <table width="100%">
            <tr>
              <td width="30%">
                <label className="form-label">
                  Director:
                  <input type="text" name="director" value={editableMovie.director || ''} onChange={handleChange} className="form-input" />
                </label>
              </td>
              <td>
                <label className="form-label">
                  Actors:
                  <input type="text" name="actors" value={editableMovie.actors || ''} onChange={handleChange} className="form-input" />
                </label>
              </td>
            </tr>
          </table>

          <label className="form-label">
            Script:
            <textarea name="script" value={editableMovie.script || ''} onChange={handleChange} className="form-textarea" />
          </label>

          {/* Textarea for Synopsis */}
          <label className="form-label">
            Synopsis:
            <textarea name="synopsis" value={editableMovie.synopsis || ''} onChange={handleChange} className="form-textarea" />
          </label>

          {/* Update Button */}
          <div>
            <button type="submit" className="form-button">{mode === 'add' ? 'Add Movie' : 'Update Details'}</button>

            {/* Display success message conditionally */}
            {isSaved && (
              <span className="success-message">Changes saved.</span>
            )}

          </div>
          <br />
          <br />
          <br />
          <button type="button" className="form-button" onClick={handleDeleteClick}>Delete This Movie</button>
          <br />
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Edit;
