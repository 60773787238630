import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { UserProvider } from './UserContext';

import './App.css';

import Home from './Home';

import Search from './Search';
import Edit from './Edit';
import Intro from './Intro';
import Play from './Play';
import Movies from './Movies';
import Series from './Series';
import Seasons from './Seasons';
import Episodes from './Episodes';
import Upcoming from './Upcoming';
import SeriesDetails from './SeriesDetails';
import SeasonDetails from './SeasonDetails';
import EpisodeDetails from './EpisodeDetails';

function App() {

  return (
    <UserProvider>
      <Router>
        <div>
          <Routes>
            {/* Redirect from the root path to /browse */}
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path='/home' element={<Home />} />
            <Route path='/edit' element={<Edit />} />
            <Route path='/intro' element={<Intro />} />
            <Route path='/search' element={<Search />} />
            <Route path='/play' element={<Play />} />
            <Route path='/movies' element={<Movies />} />
            <Route path='/series' element={<Series />} />
            <Route path='/seasons' element={<Seasons />} />
            <Route path='/episodes' element={<Episodes />} />
            <Route path='/upcoming' element={<Upcoming />} />
            <Route path='/seriesdetails' element={<SeriesDetails />} />
            <Route path='/seasondetails' element={<SeasonDetails />} />
            <Route path='/episodedetails' element={<EpisodeDetails />} />
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;

