
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyARxjd2C8J9RvrwfgrE63vqOBn4qsYhsa0",
    authDomain: "mymovies-firebase.firebaseapp.com",
    projectId: "mymovies-firebase",
    storageBucket: "mymovies-firebase.appspot.com",
    messagingSenderId: "907524516066",
    appId: "1:907524516066:web:798de6260a42166c4a16bb",
    measurementId: "G-867YV8TJWP"
  };

let firebaseApp;

// Initialize Firebase only if it's not already initialized
if (!firebaseApp?.apps?.length) {
  firebaseApp = initializeApp(firebaseConfig, "HomeApp");
}

export default firebaseApp;
