import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import firebaseApp from './firebaseConfig'; 

// Styles
import './Intro.css';

// Components
import SummaryLarge from './SummaryLarge';
import SummarySmall from './SummarySmall';

function Intro() {
  const [movie, setMovie] = useState(null);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const movieKey = params.get('movieKey');

  const db = getFirestore(firebaseApp);

  useEffect(() => {
    fetchMovieDetails();
  }, [movieKey]);

  const fetchMovieDetails = async () => {
    if (!movieKey) return;

    const movieRef = doc(db, 'movies', movieKey);
    const movieSnap = await getDoc(movieRef);
    if (movieSnap.exists) {
      setMovie(movieSnap.data());
    }
  };

  return (
    
    <div>
      <div className='intro-large'>
        <SummaryLarge movie={movie} />
      </div>
      <div className='intro-small'>
        <SummarySmall movie={movie} />
      </div>
    
    </div>
  );
}

export default Intro;

