import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, setDoc, doc, getDoc, updateDoc, deleteDoc, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';


import firebaseApp from './firebaseConfig'; // Import the initialized Firebase app

import './Forms.css';

// Initialize the Firestore instance with the initialized Firebase app
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

function SeriesDetails() {
  const [series, setSeries] = useState(null);
  const [editableSeries, setEditableSeries] = useState({});
  const [image, setImage] = useState(null);
  const [isSaved, setIsSaved] = useState(false); // New state to handle the display of the success message

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const seriesKey = params.get('seriesKey');
  const seriesTitle = params.get('seriesTitle');
  const mode = params.get('mode') || 'edit'; // Edit mode by default

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    if (mode === 'add') {
      // Initialize an empty series object for Add mode
      setSeries({});
      setEditableSeries({});
    } else {
      // Fetch existing series details for Edit mode
      fetchSeriesDetails();
    }
  }, [seriesKey, mode]);

  useEffect(() => {
    fetchSeriesDetails();
  }, [seriesKey]);

  useEffect(() => {
    setEditableSeries(series);
  }, [series]);

  const fetchSeriesDetails = async () => {
    if (!seriesKey) return;

    const seriesRef = doc(db, 'series', seriesKey);
    const seriesSnap = await getDoc(seriesRef);
    if (seriesSnap.exists) {
      setSeries(seriesSnap.data());
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being changed is "duration" or "year", parse it as an integer
    const parsedValue = (name === 'duration' || name === 'year') ? parseInt(value, 10) : value;

    // Check if parsedValue is NaN (not a number). If it is, revert it back to the original value.
    setEditableSeries(prevState => ({ ...prevState, [name]: isNaN(parsedValue) ? value : parsedValue }));
  };

  // New function to handle image selection
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  // Modified function to handle series details update
  const updateSeriesDetails = async (e) => {
    e.preventDefault();

    setIsSaved(false); // Reset the saved flag before starting the update

    // Upload image to Firebase Storage if selected
    if (image) {
      const storageRef = ref(storage, `posters/${image.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      // Wait for upload to complete and then get URL
      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed', () => { }, reject, async () => {
          const downloadURL = await getDownloadURL(storageRef);
          editableSeries.posterUrl = downloadURL;
          resolve();
        });
      });
    }

    if (mode === 'add') {
      // Generate a 40-character random key for the new series
      const newSeriesKey = generateRandomKey();

      // Add new series to Firestore with the generated key as the document name
      const seriesRef = doc(db, 'series', newSeriesKey);
      await setDoc(seriesRef, { ...editableSeries, key: newSeriesKey });

      // Redirect to the edit page for the newly created series
      navigate(`/seriesdetails?seriesKey=${newSeriesKey}&seriesTitle=${editableSeries.title}`);
    } else {
      // Update existing series
      const seriesRef = doc(db, 'series', seriesKey);
      await updateDoc(seriesRef, editableSeries);
    }

    // Refresh the data
    fetchSeriesDetails();

    setIsSaved(true); // Set the saved flag after successfully updating the Firestore data
  };

  // Helper function to generate a 40-character random key
  const generateRandomKey = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 40; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  // Function to handle button click
  const handleBackClick = () => {
    window.location.href = '/series';
  };

  // Function to handle delete click
  const handleDeleteClick = async () => {
    // Show a confirmation dialog to the user
    if (window.confirm('Are you sure you want to delete this series?')) {
      // Delete the series from Firestore
      const seriesRef = doc(db, 'series', seriesKey);
      await deleteDoc(seriesRef);

      // Redirect to the list page after deletion
      navigate('/list');
    }
  };

  // Function to handle button click
  const handleSeasonsClick = () => {
    //console.log(series.title);
    window.location.href = '/seasons?seriesKey=' + seriesKey + '&seriesTitle=' + series.title;
  };

  return (

    <div className="form-container">
      {editableSeries ? (
        <form onSubmit={updateSeriesDetails}>
          <div className="breadcrumbs">
            /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
            /&nbsp; <Link to={`/series`}>Series</Link>&nbsp;
            /&nbsp; {mode === 'add' ? 'Add Series' : editableSeries.title}
          </div>
          <h1 className="form-title">{mode === 'add' ? 'Add Series' : 'Edit Series Details'}</h1>

          <button type="button" className="form-button" onClick={handleDeleteClick}>Delete</button>
          &nbsp;&nbsp;
          <button type="button" className="form-button" onClick={handleSeasonsClick}>Manage Seasons</button>

          <br />
          <br />

          {/* File Input */}
          <label className="form-label">
            Poster Image:
            {/* Show the poster image if it exists */}
            {editableSeries.posterUrl && (
              <div className="poster-container">
                <img
                  src={editableSeries.posterUrl}
                  alt="Series Poster"
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    height: 'auto',
                    width: '100%',
                    transform: 'translate(-50%, -50%)',
                    minWidth: '160px'
                  }}
                  className='poster-image'
                />
              </div>
            )}

            <input type="file" id="fileInput" onChange={handleImageChange} className="form-input" />
            <label htmlFor="fileInput" className="fileInputLabel">Select Image</label>

          </label>

          <br />
          <br />

          {/* Input fields */}
          <label className="form-label">
            Title:
            <input type="text" name="title" value={editableSeries.title || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Genres:
            <input type="text" name="genres" value={editableSeries.genres || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Lessons:
            <textarea name="lessons" value={editableSeries.lessons || ''} onChange={handleChange} className="form-textarea" />
          </label>

          <label className="form-label">
            Director:
            <input type="text" name="director" value={editableSeries.director || ''} onChange={handleChange} className="form-input" />
          </label>

          <label className="form-label">
            Actors:
            <input type="text" name="actors" value={editableSeries.actors || ''} onChange={handleChange} className="form-input" />
          </label>

          {/* Textarea for Synopsis */}
          <label className="form-label">
            Synopsis:
            <textarea name="synopsis" value={editableSeries.synopsis || ''} onChange={handleChange} className="form-textarea" />
          </label>

          {/* Update Button */}
          <div>
            <button type="submit" className="form-button">{mode === 'add' ? 'Add Series' : 'Update Details'}</button>

            {/* Display success message conditionally */}
            {isSaved && (
              <span className="success-message">Changes saved.</span>
            )}

          </div>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default SeriesDetails;
