import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { getFirestore, doc, addDoc, collection, updateDoc, getDocs, query, where } from 'firebase/firestore';
import firebaseApp from './firebaseConfig';
import { formatISO } from 'date-fns';
import { useUser } from './UserContext';
import { debounce } from 'lodash';

// Simulate an enum with an object in JavaScript
export const StreamType = {
  SD: 'SD',
  HD: 'HD'
};

const VideoPlayer = ({ movie, streamType }) => {
  const db = getFirestore(firebaseApp);
  const user = useUser();

  const lastUpdatedTimeRef = useRef(0); // Use ref instead of state for lastUpdatedTime
  const [historyDocId, setHistoryDocId] = useState(null);
  const [shouldPlay, setShouldPlay] = useState(false);

  const playerRef = useRef(null);
  const containerRef = useRef();

  // Determine the URL based on the streamType
  const videoUrl = streamType === StreamType.HD ? movie.hdurl : movie.url;

  useEffect(() => {
    if (containerRef.current) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) { /* Firefox */
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) { /* IE/Edge */
        containerRef.current.msRequestFullscreen();
      }
    }
  }, []);

  useEffect(() => {
    console.log("Initial useEffect fired");
    // Fetch historical playback data from the database
    const fetchHistory = async () => {
      const q = query(
        collection(db, 'history'),
        where('userKey', '==', user.key),
        where('movieKey', '==', movie.key)
      );

      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const data = doc.data();
        setHistoryDocId(doc.id);
      }

      // Mark the video as ready to play
      setShouldPlay(true);
    };

    fetchHistory();
  }, [db, user.key, movie.key, historyDocId]);

  useEffect(() => {
    if (playerRef.current && lastUpdatedTimeRef.current > 0) {
      playerRef.current.seekTo(lastUpdatedTimeRef.current / 1000, 'seconds');
    }
  }, [lastUpdatedTimeRef.current]);

  const debouncedSaveOrUpdateMovieHistory = debounce(async (movieKey, lastPosition) => {
    console.log("saveOrUpdateMovieHistory fired");
    const currentDate = formatISO(new Date());

    if (historyDocId) {
      await updateDoc(doc(db, 'history', historyDocId), {
        lastPosition,
      });
    } else {
      const docRef = await addDoc(collection(db, 'history'), {
        userKey: user.key,
        movieKey,
        viewDate: currentDate,
        lastPosition,
      });
      setHistoryDocId(docRef.id);
    }
    console.log(`History updated for movieKey: ${movieKey} at ${currentDate}`);
  }, 10000); // Debounce the function to prevent frequent calls

  // Handle video progress
  const handleProgress = ({ playedSeconds }) => {
    console.log("handleProgress fired");
    const playedMilliseconds = Math.round(playedSeconds * 1000);

    if (playedMilliseconds - lastUpdatedTimeRef.current >= 10000) {
      lastUpdatedTimeRef.current = playedMilliseconds; // Update ref value
      debouncedSaveOrUpdateMovieHistory(movie.key, playedMilliseconds);
    }
  };

  return (
    <div ref={containerRef}>
      <ReactPlayer
        ref={playerRef}
        url={videoUrl}
        controls={true}
        width="100%"
        height="100%"
        playing={shouldPlay}
        muted={false}
        onError={(e) => console.log('onError', e)}
        onProgress={handleProgress}
      />
    </div>
  );
};

export default VideoPlayer;
