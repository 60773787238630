import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Styles
import './BrowseHeader.css';

function BrowseHeader({ movie }) {

  const navigate = useNavigate();

  const handleWatchClick = () => {
    navigate('/play?movieKey=' + movie.key);
  };

  return (
    <div className="browse-header-container">
      {movie && (
        <>
          <div className="browse-header-image-wrapper">
            <Link to={`/intro?movieKey=${movie.key}`}>
              <img
                src={movie.posterUrl}
                className="browse-header-image"
              />
            </Link>
          </div>
        </>
      )}
    </div>
  );
}

export default BrowseHeader;

