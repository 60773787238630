import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Styles
import './SummaryLarge.css';

// Images
import watchIcon from './watch.png';

function SummaryLarge({ movie }) {

  const navigate = useNavigate();

  const handleDetailsClick = () => {
    navigate('/edit?movieKey=' + movie.key);
  };

  const handleWatchClick = () => {
    navigate('/play?movieKey=' + movie.key);
  };

  const formatDuration = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}hr ${minutes}m`;
  };

  return (
    <div className="summary-large-container">
      {movie && (
        <>
          <div className="summary-large-poster-container">
            <div className="summary-large-gradient-overlay"></div>
            <Link to={`/intro?movieKey=${movie.key}`}>
              <img
                src={movie.largeUrl}
                className="summary-large-poster-image"
              />
            </Link>
          </div>

          <div className="summary-large-details-overlay">
            <div className="summary-large-movie-title">
              {movie.title}
            </div>
            <div className="summary-large-movie-year">
              {movie.year}
            </div>
            <div className="summary-large-movie-rating">
              {movie.rating}
            </div>
            <div className="summary-large-movie-duration">
              {formatDuration(movie.duration)}
            </div>
            <div className="summary-large-movie-synopsis">
              {movie.synopsis}
            </div>
            <div className="summary-large-button-container">
              {/* Add the disabled attribute to the button and set it based on movie.url */}
              <button
                type="button"
                className={`summary-large-button-watch ${!movie.url ? 'summary-large-button-watch-disabled' : ''}`}
                onClick={handleWatchClick}
                disabled={!movie.url}
              >
                <img src={watchIcon} className="summary-large-button-watch-icon" />
                Watch Now
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SummaryLarge;

