import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, onSnapshot, where, orderBy } from "firebase/firestore";

import firebaseApp from './firebaseConfig';

import GenreList from './GenreList';
import SummaryLarge from './SummaryLarge';
import BrowseHeader from './BrowseHeader';

import './Browse.css';
import './SummaryLarge.css';

// Images
import settingsImage from './images/settings-image.png';
import logoImage from './images/logo-image.png';
import searchImage from './images/search-image.png';
import { filter } from 'lodash';

const Browse = () => {
  const [movies, setMovies] = useState([]);
  const [randomMovie, setRandomMovie] = useState(null);
  const [opacity, setOpacity] = useState(1); // State to control the fade effect
  const [isLoading, setLoading] = useState(false);

  // Firebase database
  const db = getFirestore(firebaseApp);

  // Get the list of movies when the screen loads.
  useEffect(() => {
    // Create a reference to the movies collection
    const moviesCollection = collection(db, 'movies');

    // Create a query to sort movies by title
    const q = query(
      moviesCollection,
      where('chad', '==', true)//,
      //orderBy("title")
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q,
      snapshot => {
        const movieList = snapshot.docs.map(doc => ({
          actors: doc.data().actors,
          dateAdded: doc.data().dateAdded,
          dateModified: doc.data().dateModified,
          director: doc.data().director,
          duration: doc.data().duration,
          genres: doc.data().genres,
          key: doc.data().key,
          lastPlayed: doc.data().lastPlayed,
          lastPosition: doc.data().lastPosition,
          localFileName: doc.data().localFileName,
          localPath: doc.data().localPath,
          largeUrl: doc.data().largeUrl,
          posterUrl: doc.data().posterUrl,
          rating: doc.data().rating,
          synopsis: doc.data().synopsis,
          title: doc.data().title,
          year: doc.data().year,
          lessons: doc.data().lessons,
          thumbnailUrl: doc.data().thumbnailUrl,
          url: doc.data().url,
        }));

        // Filter out items that don't have either a url or hdurl
        let filtered = [];

        for (let i = 0; i < movieList.length; i++) {
          
          if ((movieList[i].url != null && movieList[i].url != "") || (movieList[i].hdurl != null && movieList[i].hdurl != "")) {
            filtered[filtered.length] = movieList[i];
          }

        }

        setMovies(filtered);
        //setMovies(movieList);

        // Only set randomMovie if it's not already set
        if (!randomMovie) {
          // Generate a random index
          const randomIndex = Math.floor(Math.random() * movieList.length);

          // Get the random movie title using the random index
          const newRandomMovie = movieList[randomIndex];

          // Set the random movie name in state
          setRandomMovie(newRandomMovie);
        }
      },
      error => {
        console.error("Error fetching movies:", error);
      }
    );

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (movies.length > 0) {
      let fadeOutTimeout;

      const intervalId = setInterval(() => {
        setOpacity(0); // Start fade out

        fadeOutTimeout = setTimeout(() => {
          const randomIndex = Math.floor(Math.random() * movies.length);
          const newRandomMovie = movies[randomIndex];

          // Indicate that we are now loading the new image
          setLoading(true);

          // Preload the new movie's image
          const img = new Image();
          img.src = newRandomMovie.largeUrl; // Use the correct property for the large URL
          img.onload = () => {
            // Image has loaded, set loading to false
            setLoading(false);
            setRandomMovie(newRandomMovie);
            setOpacity(1); // Start fade in
          };
        }, 1000); // This timeout will run 1 second after fading starts
      }, 17000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(fadeOutTimeout);
      };
    }
  }, [movies]);

  return (
    <div>
      <div className="header-wrapper">
        <Link to={`/upcoming`}>
          <img src={settingsImage} className="settings-image" />
        </Link>
        <img src={logoImage} className="logo-image" />
        <Link to={`/search`}>
          <img src={searchImage} className="search-image" />
        </Link>
      </div>

      <div className="summary-fade" style={{ opacity }}>
        {!isLoading && randomMovie && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <div className='browse-header-large'>
              <SummaryLarge movie={randomMovie} />
            </div>
            <div className='browse-header-small'>
              <BrowseHeader movie={randomMovie} />
            </div>


          </div>
        )}
      </div>

      <GenreList movies={movies} />
    </div>
  );
};

export default Browse;
