import React, { useState, useEffect, useRef } from 'react';

// Styles
import './Splash.css';

// Images
import logoImage from './images/logo.png';

const Splash = ({ onSuccessfulLogin }) => {
    const [inputs, setInputs] = useState(['', '', '', '']);
    const hardcodedKey = ['0', '4', '1', '8'];
    const firstInputRef = useRef(null);

    useEffect(() => {
        // Automatically focus the first input when the component mounts
        firstInputRef.current.focus();
    }, []);

    const handleChange = (index, e) => {
        const value = e.target.value;
        const newInputs = [...inputs];
        newInputs[index] = value.slice(0, 1);

        setInputs(newInputs);
    };

    const handleKeyUp = (index, e) => {
        if (!isNaN(e.key) && index < 3) {
            document.getElementById(`input-${index + 1}`).focus();
        }
    };

    const handleBackspace = (index) => {
        if (index > 0) {
            const newInputs = [...inputs];
            newInputs[index] = '';
            setInputs(newInputs);
            document.getElementById(`input-${index - 1}`).focus();
        }
    };

    const checkKey = () => {
        return JSON.stringify(inputs) === JSON.stringify(hardcodedKey);
    };

    if (checkKey()) {
        onSuccessfulLogin(); // Notify Home component about successful login
    }

    return (
        <div className="container">
            <img src={logoImage} className="splash-logo" />
            <label className="label">Invite key:</label>
            <div className="input-container">
                {inputs.map((input, index) => (
                    <input
                        key={index}
                        id={`input-${index}`}
                        ref={index === 0 ? firstInputRef : null}
                        className="input-field"
                        type="number"
                        value={input}
                        onChange={(e) => handleChange(index, e)}
                        onKeyUp={(e) => handleKeyUp(index, e)}
                        onKeyDown={(e) => e.key === 'Backspace' && handleBackspace(index)}
                        onFocus={() => index === 0 && setInputs(['', '', '', ''])}
                        disabled={index > 0 && !inputs[index - 1]}
                    />
                ))}
            </div>
        </div>
    );
};

export default Splash;
